@import "variables";

.ngx-datatable .datatable-body .progress-linear {
  display: block;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
  .container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 5px;
    -webkit-transform: translate(0, 0) scale(1, 1);
    transform: translate(0, 0) scale(1, 1);
    background-color: transparentize($primary-color, 0.9);
    .bar {
      transition: all .2s linear;
      -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
      animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
      transition: -webkit-transform .2s linear;
      transition: transform .2s linear;
      background-color: $primary-color;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 5px;
    }
  }
}
/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}

@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';
// colors for footer elements
$datatable-pager-color: rgba(0, 0, 0, 0.54) !default;
$datatable-pager-color-hover: rgba(0, 0, 0, 0.75) !default;
$datatable-pager-background-hover: rgba(158, 158, 158, 0.2) !default;
$datatable-pager-disabled-color: rgba(0, 0, 0, 0.26) !default;
$datatable-pager-disabled-background: transparent !default;
$datatable-pager-active-background: rgba(158, 158, 158, 0.2) !default;

.datatable-footer {
  font-size: 12px;
  font-weight: 400;

  .page-count {
    line-height: 35px;
    height: 35px;
    padding: 0 1.2rem;
  }

  .datatable-pager {
    margin: 0 10px;

    li {
      vertical-align: middle;

      &.disabled a {
        color: $datatable-pager-disabled-color !important;
        background-color: $datatable-pager-disabled-background !important;
      }

      &.active a {
        background-color: $datatable-pager-active-background;
        font-weight: bold;
      }
    }

    a {
      height: 22px;
      min-width: 24px;
      line-height: 22px;
      padding: 0 6px;
      border-radius: 3px;
      margin: 6px 3px;
      text-align: center;
      color: $datatable-pager-color;
      text-decoration: none;
      vertical-align: bottom;

      &:hover {
        color: $datatable-pager-color-hover;
        background-color: $datatable-pager-background-hover;
      }
    }

    .datatable-icon-left,
    .datatable-icon-skip,
    .datatable-icon-right,
    .datatable-icon-prev {
      font-size: 20px;
      line-height: 20px;
      padding: 0 3px;
    }
  }
}

// Sorting styles override
.sort-btn.datatable-icon-sort-unset::before {
  display: none;
}
.sort-btn.datatable-icon-down, .sort-btn.datatable-icon-up, .sort-btn.sort-desc, .sort-btn.sort-asc {
  background: #4239ff15;
  padding: 6px;
  border-radius: 6px;
  margin-left: 5px;
}
.datatable-icon-down:before, .sort-btn.sort-desc:before {
  font-family: 'data-table';
  color: #4139FF;
  // color: #01465f;
  content: '\2193';
  font-size: 26px;
  line-height: 5px;
}
.datatable-icon-up:before, .sort-btn.sort-asc:before {
  font-family: 'data-table';
  color: #4139FF;
  // color: #01465f;
  content: '\2191';
  font-size: 26px;
  line-height: 5px;
}

.main-column {
  border-right: 1px solid rgba(5, 11, 37, 0.1);
}
