@import '@nebular/theme/styles/theming';
@import '@nebular/theme/styles/themes/corporate';
@import "styles/variables";

$nb-themes: nb-register-theme((
  color-primary-default: #4139ff,
  // color-primary-default: #01465f,
  // color-primary-hover: #1096c7,
  // color-primary-focus: #047097,
  // color-primary-active: #047097,
  // color-info-default: #056358,
  text-primary-hover-color: #395aff,
  // text-primary-hover-color: #036081,
  color-basic-transparent-default: #ffffff,
  color-basic-transparent-default-border: rgba(20, 27, 58, 0.1),
//  text-hint-color: #141B3A,
  text-hint-color: #525664,
  outline-color: rgba(20, 27, 58, 0.1),

  shadow: 0 1.25rem 2.5rem rgba(207, 212, 233, 0.3),
  border-radius: 0.375rem,
  font-family-primary: $font-primary,
  font-family-secondary: $font-primary,

  layout-background-color: #FAFBFF,
  layout-padding: 3.875rem 5.625rem,

  sidebar-width: 16.25rem,
  sidebar-padding: 2.19rem,
  sidebar-header-height: auto,
  sidebar-menu-item-highlight-color: white,

  menu-item-icon-width: 2.5rem,
  badge-text-font-size: .9rem,
  badge-padding: .35rem .5rem,
  menu-item-padding: 1rem 1.5rem 1rem 2rem,
  menu-item-divider-width: 0,
  menu-text-font-size: 0.9375rem,
  menu-text-font-weight: 400,
  menu-text-line-height: 1.25rem,
  menu-text-color: rgba(20, 27, 58, 0.5),
  menu-item-icon-color: rgba(20, 27, 58, 0.5),

  menu-item-hover-text-color: inherit,
  menu-item-icon-hover-color: inherit,

  menu-item-active-text-color: #141B3A,
  menu-item-icon-active-color: white,

  card-border-width: 0,
  card-shadow: 0 0.625rem 2.5rem rgba(207, 212, 233, 0.2),
  card-padding: 2.1875rem,

  checkbox-height: 1rem,
  checkbox-width: 1rem,
  checkbox-border-radius: 0.125rem,

  user-giant-name-text-font-size: 1.25rem,
  user-giant-name-text-line-height: 1.4375rem,
  user-name-text-font-weight: 300,
  user-name-text-color: #050B25,

  user-giant-title-text-font-size: 1.625rem,
  user-giant-title-text-line-height: 1.8125rem,
  user-title-text-font-weight: 500,
  user-title-text-font-color: #050B25,

  toggle-height: 0.875rem,
  toggle-width: 1.5rem,
  toggle-switcher-size: 0.7rem,
  toggle-switcher-icon-size: 0.5rem,
  toggle-text-font-size: 0.8125rem,
  toggle-text-line-height: 0.9375rem,
  toggle-text-font-weight: 400,

  button-small-text-font-size: 0.8125rem,
  button-medium-text-font-size: 0.9375rem,
  text-button-font-weight: 400,
  button-filled-text-transform: none,
  button-outline-text-transform: none,

  select-outline-basic-background-color: #ffffff,
  select-small-text-font-weight: 400,
  select-small-text-font-size: 0.8125rem,
  select-min-width: 6.25rem,

  calendar-border-style: none,
  calendar-weekday-divider-width: 0,
  calendar-border-radius: 50%,

  popover-border-width: 0,

  input-basic-placeholder-text-color: #8f9bb3,

  badge-border-radius: 50%,

  radio-width: 0.875rem,
  radio-height: 0.875rem,
  radio-text-font-size: 0.875rem,
  radio-text-font-weight: 400,

  accordion-item-background-color: transparent,

  button-filled-basic-background-color: #eff2ff,
  button-filled-basic-text-color:	#3958FF,
  // button-filled-basic-text-color:	#01465f,

  text-heading-1-font-size: 1.5rem,
  text-heading-1-font-weight: 500,
  text-heading-1-line-height: 1.75rem,

  text-heading-3-font-size: 1.25rem,
  text-heading-3-font-weight: 500,
  text-heading-3-line-height: 1.4375rem,

  text-subtitle-font-size: 0.75rem,
  text-subtitle-font-weight: 400,
  text-subtitle-line-height: 1.25rem,

  timepicker-container-height: 20rem,
  timepicker-container-width: 8rem,

), corporate, corporate);

.nb-theme-corporate nb-card.nb-timepicker-container {
  border: 1px solid #e4e9f2;
  button.appearance-ghost {
    display: none;
  }
}
