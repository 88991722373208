@use '@angular/material' as mat;
@include mat.core();
@import 'themes';
@import '@nebular/theme/styles/globals';
@include nb-install() {
  @include nb-theme-global();
};
/* You can add global styles to this file, and also import other style files */

@import "variables";
$my-custom-typography-config: mat.m2-define-typography-config(
  $headline-1: mat.m2-define-typography-level($font-size: 112px, $line-height: 1.2, $font-weight: 500, $font-family: $font-primary),
  $headline-2: mat.m2-define-typography-level($font-size: 96px, $line-height: 1.2, $font-weight: 500, $font-family: $font-primary),
  $headline-3: mat.m2-define-typography-level($font-size: 60px, $line-height: 1.2, $font-weight: 500, $font-family: $font-primary),
  $headline-4: mat.m2-define-typography-level($font-size: 48px, $line-height: 1.2, $font-weight: 500, $font-family: $font-primary),
  $headline-5: mat.m2-define-typography-level($font-size: 34px, $line-height: 1.2, $font-weight: 500, $font-family: $font-primary),
  $body-1: mat.m2-define-typography-level($font-size: 16px, $line-height: 1.5, $font-weight: 400, $font-family: $font-primary),
  $body-2: mat.m2-define-typography-level($font-size: 14px, $line-height: 1.5, $font-weight: 400, $font-family: $font-primary),
  $caption: mat.m2-define-typography-level($font-size: 12px, $line-height: 1.2, $font-weight: 400, $font-family: $font-primary),
  $button: mat.m2-define-typography-level($font-size: 14px, $line-height: 1.5, $font-weight: 500, $font-family: $font-primary),
);


$my-app-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$my-app-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$my-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$my-app-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $my-app-primary,
        accent: $my-app-accent,
        warn: $my-app-warn,
      ),
      typography: $my-custom-typography-config,
    )
);

@include mat.all-component-themes($my-app-theme);


h1 {
  font-family: $font-primary;
  letter-spacing: 0.007917em;
}

h3 {
  letter-spacing: 0.0125em;
}

.flex-row-center {
  width: 100%;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.shift-end {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-end;
  grid-gap: 0.625rem;
}

hr {
  border: none;
  height: 1px;
  background-color: rgba(20, 27, 58, 0.1);
}

.clickable {
  cursor: pointer;
  &:hover {
    background-color: #FAFBFF;
  }
}
.not-clickable {
  .datatable-body-cell {
    color: rgba(5, 11, 37, 0.3);
  }
}

.table-checkbox-padding {
  padding: 10px 8px 6px;
}

.table-align-end {
  display: inline-block;
  width: 100%;
  text-align: end;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.3125rem;
}
.thin-scrollbar::-webkit-scrollbar-thumb {
  background: #e4e9f2;
  cursor: pointer;
  border-radius: 0.15625rem;
}
.thin-scrollbar::-webkit-scrollbar-track {
  background: #f7f9fc;
}

// Nebular layout fix
nb-sidebar .main-container-fixed {
  top: 0 !important;
}

.expansion-indicator {
  border-radius: 50%;
  background-color: rgba(57,88,255,0.08);
  color: #3958FF;
}

.nb-theme-corporate nb-menu .menu-item a.active .menu-icon {
  background-color: #4139FF;
  // background-color: #01465f;
  border-radius: 4px;
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
}

.nb-theme-corporate nb-menu .menu-item a .menu-icon {
  min-width: 1em;
}

.nb-theme-corporate nb-calendar-days-names .day {
  text-transform: capitalize;
}

.nb-theme-corporate nb-checkbox .text {
  font-weight: 500;
}

// Custom Nebular User overrides
.user-name, .user-title {
  display: inline-block;
}

// ngx-charts tooltip override
ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
  .tooltip-caret.position-top {
    border-top-color: transparent;
  }
  background-color: transparent;
}

// ngx-datatable styles
.datatable-header {
  background-color: #FAFBFF;
  border-top: 1px solid rgba(5, 11, 37, 0.1);
}
.datatable-header-inner {
  height: 100%;
}
.datatable-header-cell-template-wrap {
  width: 100%;
}
.datatable-header-cell {
  display: flex !important;
  flex-flow: row;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  letter-spacing: 0.01917em;
  color: $dark-font-color;
}
.datatable-row-wrapper {
  border-top: 1px solid rgba(5, 11, 37, 0.1);
}
.datatable-row-wrapper:last-child {
  border-bottom: 1px solid rgba(5, 11, 37, 0.1);
}
.datatable-body-cell {
  display: flex !important;
  flex-flow: row;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  letter-spacing: 0.0157143em;
  color: $light-font-color;
}
.datatable-body-cell-label {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.datatable-footer {
  font-size: 12px;
}
datatable-selection .empty-row {
  border-top: 1px solid rgba(5, 11, 37, 0.1);
  font-size: 13px;
  color: $light-font-color;
  padding: 0.5rem;
}
// ngx-datatable - datatable-scroller width fix
.fix-scroller-width datatable-scroller {
  width: 100% !important;
}

.employer-filter {
  text-align: center;

  .title {
    margin-bottom: 0.5rem;
  }
}

nb-icon.custom-icon {
  stroke: #475467; // default color for custom icons
}

@import "styles/tables";
@import "styles/col12";

html, body { height: 100%; }
body { margin: 0; font-family: $font-primary}

@media (max-width: 1400px) {
  .hide1400 {
      display: none !important;
  }
}

.datatable-body-row,
.datatable-row-center.ng-star-inserted {
  display: flex !important;
  justify-content: space-between !important;
}

datatable-scroller,
.datatable-body-row,
.datatable-row-center.datatable-row-group.ng-star-inserted {
  width: 100% !important;

  &.lastcolumn {
    margin-left: auto;
  }

}

.datatablerow {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
  cursor: pointer;

  &:hover {
    background-color: #FAFBFF;
  }
}

.horizontal-divider {
  width: 100%;
  height: 1px;
  background-color: lightgray;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.mat-mdc-tab-group .mat-mdc-tab-header {
  border-bottom: 1px solid var(--mat-divider-color) !important;
}
