/* 1/12 */
.col-1 {
  width: 8.33%;
}

/* 2/12 */
.col-2 {
  width: 16.66%;
}

/* 3/12 */
.col-3 {
  width: 25%;
}

/* 4/12 */
.col-4 {
  width: 33.33%
}

/* 5/12 */
.col-5 {
  width: 41.66%;
}

/* 6/12 */
.col-6 {
  width: 50%;
}

/* 7/12 */
.col-7 {
  width: 58.33%;
}

/* 8/12 */
.col-8 {
  width: 66.66%;
}

/* 9/12 */
.col-9 {
  width: 75%;
}

/* 10/12 */
.col-10 {
  width: 83.33%;
}

/* 11/12 */
.col-11 {
  width: 91.66%;
}

/* 12/12 */
.col-12 {
  width: 100%;
}

h1.col-12,
h2.col-12,
h3.col-12,
h4.col-12,
h5.col-12,
h6.col-12 {
  width: 100%;
  margin-left: -0.3125rem;
  margin-top: 0;
  margin-bottom: 0.3125rem;
}

form {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 2rem;

  .row {
    display: flex;
    flex-wrap: wrap;

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
      padding-left: .3125rem;
      padding-right: .3125rem;

      app-edit-address,
      nb-select,
      input {
        width: 100%;
        font-size: 1rem !important;
        max-width: 100% !important;

        &:disabled,
        &[disabled] {
          background-color: #eee;
          color: #aaa;
          cursor: not-allowed;
        }

        &.hidden {
          display: none;
        }
      }

      label {
        display: inline-block;
        width: 100%;
        margin-top: .781rem;
        padding-bottom: .3125rem;
      }
    }
  }

  .section {
    margin-left: -0.3125rem;
    margin-top: 0.625rem;
    font-style: italic;
    font-weight: 500;
    position: relative;
    top: .3125rem;
  }

  .row {
    margin: 0 -0.625rem;
  }
}

.modal-50 {
  app-add-edit-employer-modal {
    app-dialog-wrapper {
      width: 50rem !important;
      display: inline-block;
    }
  }
}

.input-with-icon {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
}

.input-with-icon input {
  flex-grow: 1;
  min-width: 100%;
}

.input-with-icon .icon-button {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  cursor: pointer;
}

.modeless-dialog,
.modeless-dialog-w35, 
.modeless-dialog-w50 {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  background-color: white;
  border-radius: 0.5rem;
}

.modeless-dialog-w35 {
  width: 35rem;
}

.modeless-dialog-w50 {
  width: 50rem
}
